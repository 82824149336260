import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const Godorf: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.Godorf,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Godorf"
          d="M564.1,1050c-0.4,1.3-1,3.2-1.8,4.3l-0.3-0.3V954l56-12l14,6l2,16l-18,28l22,16c-8.5,5.5-26.3,16.9-34,23 l-0.2,1.5c-3.9,2.9-5.6,8.9-8.1,12.9l-6.1-1.9c-0.6,0.5-1.8,1.1-2.6,1.4c-3-3.3-5.6-8.7-8.5-12.1 C572.4,1037.2,568,1043.7,564.1,1050z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="matrix(1 0 0 1 574.2515 974.8768)"
        >
          Godorf
        </text>
      </g>
    </>
  );
});
